import React from 'react';
import copy from './components/img/copy.svg';
import './components/styles/style.css';
import { TITLE, DESCRIPTION, COPIED, COPY_PASSWORD, ADVANCED, PASSWORD_LENGTH, LOWERCASE, UPPERCASE, NUMBERS, SYMBOLS, EXCLUDE_CHARACTERS, EXCLUDE_CHARACTERS_TITLE, DO_NOT_INCLUDE, DO_NOT_INCLUDE_PLACEHOLDER, GENERATE_PASSWORD, NO_OPTION_ERROR_TEXT, SYMBOL_ONLY_ERROR_TEXT, FAQ, FAQ_TITLE, WORDS, WORDS_TITLE } from './utils/lang/ENG';
import WordList from './components/WordList';
import { LANGUAGES } from './utils/lang/index';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      lowercaseCheckBox: true,
      uppercaseCheckBox: true,
      numbersCheckBox: true,
      symbolsCheckBox: true,
      advanceSettingsCheckBox: false,
      similarCharactersCheckBox: false,
      wordsCheckBox: false,
      doNotInclude: '',
      lang: localStorage.getItem("site-lang") || "ENG",
      title: TITLE,
      description: DESCRIPTION,
      copied: COPIED,
      copy_password: COPY_PASSWORD,
      advanced: ADVANCED,
      password_length: PASSWORD_LENGTH,
      lowercase: LOWERCASE,
      uppercase: UPPERCASE,
      numbers: NUMBERS,
      symbols: SYMBOLS,
      words: WORDS,
      words_title: WORDS_TITLE,
      exclude_characters: EXCLUDE_CHARACTERS,
      exclude_characters_title: EXCLUDE_CHARACTERS_TITLE,
      do_not_include: DO_NOT_INCLUDE,
      do_not_include_placeholder: DO_NOT_INCLUDE_PLACEHOLDER,
      generate_password: GENERATE_PASSWORD,
      no_option_error_text: NO_OPTION_ERROR_TEXT,
      symbol_only_error_text: SYMBOL_ONLY_ERROR_TEXT,
      faq: FAQ,
      faq_title: FAQ_TITLE
    }
  }

  // Set password strength meter
  setPasswordStrength(passwordLength) {
    
    const passwordStrength = document.querySelector(".password_strength");

    const weakPasswordMaxLength = 9;
    const semiWeakPasswordMaxLength = 20;
    const goodPasswordMaxLength = 40;

    if (passwordLength <= weakPasswordMaxLength) {
      passwordStrength.style.backgroundColor = "var(--color-danger-dark)";
      passwordStrength.style.width = "15%"
    } else if (passwordLength <= semiWeakPasswordMaxLength) {
      passwordStrength.style.backgroundColor = "var(--color-warning-dark)";
      passwordStrength.style.width = "45%"
    } else if (passwordLength <= goodPasswordMaxLength) {
      passwordStrength.style.backgroundColor = "var(--color-success)";
      passwordStrength.style.width = "75%"
    } else {
      passwordStrength.style.backgroundColor = "var(--color-success-dark)";
      passwordStrength.style.width = "100%"
    }

  }

  // Display password length value to the user.
  passwordLength(e) {
    let lengthDisplay = document.querySelector(".length-display");
    
    lengthDisplay.innerText = e.target.value;
    this.setPasswordStrength(e.target.value);

    this.generatePassword();

    return;
  }

  alphabets() {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }

  numberList() {
    return "0123456789";
  }

  symbolList() {
    return "~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/";
  }

  wordsList() {
    return WordList();
  }

  generatePassword(e) {

    let lowerletters;
    let upperletters;
    let numbers;
    let symbols;
    let words;
    let generatedPassword  = "";

    e?.preventDefault();

    // Return an error to the user if no option is selected.
    if(!this.lowercase.checked && !this.uppercase.checked && !this.numbers.checked && !this.symbols.checked && !this.words.checked) {

      let feedbackElement = document.querySelector(".error-wrapper");

      feedbackElement.style.display = "block"
      feedbackElement.firstChild.innerText = this.state.no_option_error_text;

      setTimeout(() => {
        feedbackElement.style.display = ""
      }, 3000)

      return;
    }
    
    // Return an error to the user if only symbol option is selected.
    if(!this.lowercase.checked && !this.uppercase.checked && !this.numbers.checked  && !this.words.checked && this.symbols.checked) {

      let feedbackElement = document.querySelector(".error-wrapper");

      feedbackElement.style.display = "block"
      feedbackElement.firstChild.innerText = this.state.symbol_only_error_text;

      setTimeout(() => {
        feedbackElement.style.display = ""
      }, 3000)

      return;
    }

    // Add lowercase letters to generatedPassword if the option is checked.
    if(this.lowercase.checked) {
      //shuffle letters and reduce the length to the password length the user choosed
      lowerletters = this.alphabets().repeat(4).toLowerCase().split('').sort(function(){return 0.5-Math.random()}).join('').slice(0, this.length.value);
      generatedPassword += lowerletters;
    }

    // Add uppercase letters to generatedPassword if the option is checked.
    if(this.uppercase.checked) {
      // shuffle letters and reduce the length to the password length the user selected.
      upperletters = this.alphabets().repeat(4).split('').sort(function(){return 0.5-Math.random()}).join('').slice(0, this.length.value);
      generatedPassword += upperletters;
    }
    // Add numbers to generatedPassword if the option is checked.
    if(this.numbers.checked) {
      //shuffle numbers and reduce the length to the password length the user selected.
      numbers = this.numberList().repeat(10).split('').sort(function(){return 0.5-Math.random()}).join('').slice(0, this.length.value);
      generatedPassword += numbers;
    }
    // Add symbols letters to generatedPassword if the option is checked.
    if(this.symbols.checked) {
      //shuffle symbols and reduce the length to the password length the user selected.
      symbols = this.symbolList().repeat(4).split('').sort(function(){return 0.5-Math.random()}).join('').slice(0, this.length.value);
      generatedPassword += symbols;
    }

    // Get random words and hyphenate it if the option is checked.
    if(this.words.checked) {
      //shuffle words and reduce the length to the password length the user selected.
      words = this.wordsList().split(' ').sort(function(){return 0.5-Math.random()}).splice(0, this.length.value).join("-");
      generatedPassword = words;
    }
    
    // Exclude 0, 1, I, i, O, ., |, `, ' from the generated password if the option is checked
    if(this.similarCharacters.checked) {
      generatedPassword = generatedPassword.repeat(4).replace(/0|1|I|i|O|\.|\||`|'/ig, '');
    }

    // Exclude custom characters from the generated password if the exclude custom characters field is not empty
    if(this.state.doNotInclude) {
      let excludeCharacters = this.state.doNotInclude.split('').join('|');
      generatedPassword = generatedPassword.repeat(4).replace(new RegExp(excludeCharacters, "ig"), '');
    }

    if(!this.words.checked) {
      // Shuffle final password and reduce the length to the password length the user selected.
      generatedPassword = generatedPassword.split('').sort(function(){return 0.5-Math.random()}).join('').split('').sort(function(){return 0.5-Math.random()}).join('').slice(0, this.length.value);
    }
    
    // Set value of the text field to the generated password.
    this.passfield.value = generatedPassword;

    return;

  }

  //function to copy password from the textfield
  copyPassword() {

    let passfield = document.querySelector(".passfield")
    let feedbackEle = document.querySelector(".copy-feedback");

    /* Select the text field */    
    passfield.select();
    passfield.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    // Only display feedback when passfield contains a value.
    if (passfield.value) {

      feedbackEle.style.top = "22px";
      setTimeout(() => { feedbackEle.style.top = ""; }, 2000)

    }

    return;

  }
  
  // show custom checkbox if its corresponding input checkbox is checked
  lowercaseState = () => { 
    this.words.checked = false

    return this.setState({
      lowercaseCheckBox: !this.state.lowercaseCheckBox,
      wordsCheckBox: false
    }); 
  }
  uppercaseState = () => { 
    this.words.checked = false

    return this.setState({
      uppercaseCheckBox: !this.state.uppercaseCheckBox,
      wordsCheckBox: false
    }); 
  }
  numbersState = () => { 
    this.words.checked = false

    return this.setState({
      numbersCheckBox: !this.state.numbersCheckBox,
      wordsCheckBox: false
    }); 
  }
  symbolsState = () => { 
    this.words.checked = false

    return this.setState({
      symbolsCheckBox: !this.state.symbolsCheckBox,
      wordsCheckBox: false
    }); 
  }
  wordsState = () => {

    this.numbers.checked = false;
    this.symbols.checked = false;
    this.uppercase.checked = false;
    this.lowercase.checked = false;
    this.words.checked = !this.state.wordsCheckBox;
    this.similarCharacters.checked = false;

    return this.setState({
      lowercaseCheckBox: false,
      uppercaseCheckBox: false,
      numbersCheckBox: false,
      symbolsCheckBox: false,
      similarCharactersCheckBox: false,
      wordsCheckBox: !this.state.wordsCheckBox, 
    });
  }
  similarCharactersState = () => { 
    return this.setState({
      similarCharactersCheckBox: !this.state.similarCharactersCheckBox, 
      wordsCheckBox: false
    }); 
  }
  doNotIncludeState = (e) => { 
    return this.setState({
      doNotInclude: e.target.value, 
    }); 
  }
  advanceSettingsState = () => { 
    this.setState({ advanceSettingsCheckBox: !this.state.advanceSettingsCheckBox, });
    if(!this.state.advanceSettingsCheckBox === false) {
      this.setState({ 
        similarCharactersCheckBox: false,
        doNotInclude: '',      
        wordsCheckBox: false
      });
    }
    return;
  }

  toggleSiteLanguages = (e) => {
    return e.target.parentElement.classList.toggle("active");
  }

  toggleSiteLanguagesAlt = (e) => {
    return e.target.classList.toggle("active");
  }

  setSiteLangOnLoad = () => {
    
    (async () => {
      this.setState({ lang: localStorage.getItem("site-lang") || "ENG" })
      let { TITLE, DESCRIPTION, COPIED, COPY_PASSWORD, ADVANCED, PASSWORD_LENGTH, LOWERCASE, UPPERCASE, NUMBERS, SYMBOLS, EXCLUDE_CHARACTERS, EXCLUDE_CHARACTERS_TITLE, DO_NOT_INCLUDE, DO_NOT_INCLUDE_PLACEHOLDER, GENERATE_PASSWORD, NO_OPTION_ERROR_TEXT, SYMBOL_ONLY_ERROR_TEXT, FAQ, FAQ_TITLE, WORDS, WORDS_TITLE } = await import(`./utils/lang/${ this.state.lang }`)

      this.setState({ 
        title: TITLE,
        description: DESCRIPTION,
        copied: COPIED,
        copy_password: COPY_PASSWORD,
        advanced: ADVANCED,
        password_length: PASSWORD_LENGTH,
        lowercase: LOWERCASE,
        uppercase: UPPERCASE,
        numbers: NUMBERS,
        symbols: SYMBOLS,
        words: WORDS,
        words_title: WORDS_TITLE,
        exclude_characters: EXCLUDE_CHARACTERS,
        exclude_characters_title: EXCLUDE_CHARACTERS_TITLE,
        do_not_include: DO_NOT_INCLUDE,
        do_not_include_placeholder: DO_NOT_INCLUDE_PLACEHOLDER,
        generate_password: GENERATE_PASSWORD,
        no_option_error_text: NO_OPTION_ERROR_TEXT,
        symbol_only_error_text: SYMBOL_ONLY_ERROR_TEXT,
        faq: FAQ,
        faq_title: FAQ_TITLE
      })

      
    let lengthValue = document.querySelector(".password-length-range");
    this.setPasswordStrength(lengthValue.value);

    this.generatePassword();

    })()
  }

  changeSiteLanguage = (e) => {
      localStorage.setItem("site-lang", e.target.dataset.lang)
      window.location.reload();
      return
  }

  render() {
    return (
      <div className="App" onLoad={this.setSiteLangOnLoad} >
        <div className="container">
          <div className="lang-wrapper">
            <div className="lang-inner-wrapper">
              <div className="lang-switch-wrapper">
                <div className="lang-switch" onClick={this.toggleSiteLanguagesAlt}>
                  <svg onClick={this.toggleSiteLanguages} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1642 1640.808" fill="#ffffff">
                    <path d="M1517.548,426.375c-1.106-5.512-3.798-10.066-7.79-13.567c-308.923-526.704-1072.705-519.697-1377.516,0
                      c-4.015,3.521-6.692,8.088-7.79,13.566c-139.13,247.896-138.292,540.393,0,786.793c1.099,5.479,3.773,10.043,7.79,13.567
                      c309.66,527.957,1071.458,521.823,1377.516-0.002c3.93-3.447,6.671-7.982,7.789-13.564
                      C1656.679,965.27,1655.839,672.773,1517.548,426.375z M1475.669,1188.165c-43.573,6.814-226.708,10.313-272.028,11.144
                      c64.008-234.159,65.616-519.024,0-759.076c45.862,0.841,228.419,4.324,272.026,11.144
                      C1601.371,677.349,1609.554,947.477,1475.669,1188.165z M864.502,1570.699c-29.458,1.833-57.539,1.833-87.003-0.001
                      c-137.402-21.634-228.148-189.126-273.247-321.788c196.921,2.76,431.623,2.83,633.499,0
                      C1092.698,1381.434,1001.926,1549.063,864.502,1570.699z M489.002,1200.148c-67.596-235.671-67.662-524.855,0-760.756
                      c207.323-3.101,457.275-3.092,663.997,0c67.596,235.672,67.661,524.855,0,760.756
                      C946.09,1203.242,696.181,1203.246,489.002,1200.148z M777.523,68.841c29.332-1.825,57.399-1.839,86.971,0.001
                      c137.423,21.631,228.168,189.163,273.256,321.788c-197.62-2.771-432.501-2.819-633.499,0
                      C549.249,258.268,639.967,90.482,777.523,68.841z M1444.126,399.578c-68.136-4.408-186.787-6.972-254.994-8.148
                      c-34.117-105.949-89.911-221.852-172.548-296.988C1187.27,141.422,1341.062,244.508,1444.126,399.578z M625.414,94.443
                      c-82.664,75.161-138.446,191.089-172.546,296.987c-68.213,1.176-186.86,3.741-254.995,8.148
                      C300.599,245.014,454.102,141.597,625.414,94.443z M166.332,451.376c43.69-6.832,227.787-10.333,272.028-11.144
                      c-64.294,235.209-65.345,520.023,0,759.076c-46.077-0.845-228.442-4.329-272.027-11.143
                      C40.624,962.182,32.452,692.053,166.332,451.376z M197.875,1239.963c68.075,4.403,186.604,6.969,254.994,8.147
                      c34.35,106.675,90.242,222.153,172.547,296.988C454.731,1498.119,300.939,1395.034,197.875,1239.963z M1016.584,1545.099
                      c82.656-75.152,138.446-191.085,172.548-296.989c68.328-1.177,186.901-3.743,254.995-8.147
                      C1341.397,1394.535,1187.881,1497.95,1016.584,1545.099z"/>
                  </svg>
                  <span className="site-lang" onClick={this.toggleSiteLanguages}>{ this.state.lang }</span>
                  <ul className="lang-dropdown">
                    {
                      LANGUAGES.map((lang, index) => {
                        return (
                          <li key={index} data-lang={lang.key} onClick={this.changeSiteLanguage}>{lang.name}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h1>{ this.state.title }</h1>
          <p>{ this.state.description }</p>
          <div className="form-wrapper">
            <div className="form-inner-wrapper">
              <div className="copy-feedback">
                <p>{ "**"+this.state.copied }</p>
              </div>
              <form method="post" action="" onSubmit={(e) => this.generatePassword(e) } >
                {
                  this.state.wordsCheckBox ? (
                    <div className="form-top-section has-textarea">
                      <textarea type="text" name="" className="passfield" ref={(input) => this.passfield = input} readOnly/>
                      <div className="copy-wrapper" title={ this.state.copy_password } onClick={(e) => this.copyPassword() }><img src={ copy } alt="Copy Icon" /></div>
                      <div className="password_strength"></div>
                    </div>
                  ) : (
                    <div className="form-top-section">
                      <input type="text" name="" className="passfield" ref={(input) => this.passfield = input} readOnly/>
                      <div className="copy-wrapper" title={ this.state.copy_password } onClick={(e) => this.copyPassword() }><img src={ copy } alt="Copy Icon" /></div>
                      <div className="password_strength"></div>
                    </div>
                  )
                }
                <div className="advance-settings-wrapper">
                  <label className={(this.state.advanceSettingsCheckBox) ? "selected":""}>
                    <div className="switch">
                      <span className="switch-lever"></span>
                    </div>
                    <input type="checkbox" name="" onChange={this.advanceSettingsState} defaultChecked={this.state.advanceSettingsCheckBox} ref={(input) => this.advanceSettings = input } />
                    <span>{ this.state.advanced }</span>
                  </label>
                </div>
                <div className="form-footer-section">
                  <div className="form-footer-main-column">
                    <label>{ this.state.password_length }</label>
                      <input type="range" name="" className="password-length-range" onChange={(e) => this.passwordLength(e) } defaultValue="16" ref={(input) => this.length = input } min="6" max="100" step="1"/>
                      <div className="length-display">16</div>                    
                  </div>
                  <div className="form-footer-main-column">
                    <div className="form-footer-column">
                      <label className={(this.state.lowercaseCheckBox) ? "selected":""}>
                        <input type="checkbox" name="" onChange={this.lowercaseState} defaultChecked={this.state.lowercaseCheckBox} ref={(input) => this.lowercase = input } />
                        <span>{ this.state.lowercase }</span>
                        <span className="check"></span>
                      </label>
                      <label className={(this.state.uppercaseCheckBox) ? "selected":""}>
                        <input type="checkbox" name="" onChange={this.uppercaseState} defaultChecked={this.state.uppercaseCheckBox} ref={(input) => this.uppercase = input } />
                        <span>{ this.state.uppercase }</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div className="form-footer-column">
                      <label className={(this.state.numbersCheckBox) ? "selected":""}>
                        <input type="checkbox" name="" onChange={this.numbersState} defaultChecked={this.state.numbersCheckBox} ref={(input) => this.numbers = input } />
                        <span>{ this.state.numbers }</span>
                        <span className="check"></span>
                      </label>
                      <label className={(this.state.symbolsCheckBox) ? "selected":""}>
                        <input type="checkbox" name="" onChange={this.symbolsState} defaultChecked={this.state.symbolsCheckBox} ref={(input) => this.symbols = input } />
                        <span>{ this.state.symbols }</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div className="form-footer-column">
                      <label className={(this.state.wordsCheckBox) ? "selected":""} title={ this.state.words_title }>
                        <input type="checkbox" name="" onChange={this.wordsState} defaultChecked={this.state.wordsCheckBox} ref={(input) => this.words = input } />
                        <span>{ this.state.words }</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div className={this.state.advanceSettingsCheckBox ? 'form-footer-column advance-option advance-active':'form-footer-column advance-option'}>
                      <label className={(this.state.similarCharactersCheckBox) ? "selected":""} title={ this.state.exclude_characters_title + ': 0, 1, I, i, O, ., |, `, \' '}>
                        <input type="checkbox" name="" autoComplete="new-password" onChange={this.similarCharactersState} defaultChecked={this.state.similarCharactersCheckBox} ref={(input) => this.similarCharacters = input } />
                        <span>{ this.state.exclude_characters }</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div className={this.state.advanceSettingsCheckBox ? 'form-footer-column advance-option advance-active':'form-footer-column advance-option'}>
                      <div>
                        <label htmlFor="doNotInclude">{ this.state.do_not_include + ":" }</label>
                        <input type="text" name="" id="doNotInclude" placeholder={ this.state.do_not_include_placeholder } onChange={this.doNotIncludeState} value={this.state.doNotInclude} ref={(input) => this.doNotInclude = input } />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-button">
                  <input type="submit" className="generate-button" value={ this.state.generate_password } />
                </div>
              </form>
              <div className="error-wrapper">
                <p></p>
              </div>
            </div>
          </div>
          <div className="extended_section">
            <div className="extended_section_inner">
              <div className="faq_wrapper">
                <h2>{ this.state.faq_title }</h2>
                <ul className="faq_list" style={ { textAlign:  this.state.lang === "ARA" ? "right" : "" }}>
                  {
                    this.state.faq.map((item, index) => {
                      return(
                        <li key={index} className="faq_item">
                          <h3 className="question">
                            <span>{ item.question }</span>
                          </h3>
                          <div className="answer" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
