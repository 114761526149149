import React from 'react';
import ReactDOM from 'react-dom';
import './components/styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GA4React from "ga-4-react";
import { config } from './utils/config/default';

const ga4react = new GA4React(config.GA);

(async _ => {
  
await ga4react.initialize()
.then(res => console.log("Analytics Success."))
.catch(err => console.log("Analytics Failure."))
.finally(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
